/**
 * Simple multi-language string provider.
 */
export default class I18N {

  /**
   * Creates a new instance of the I18N class
   * @param translations translation dictionary in the same format as the Okta widget's "i18n" option
   */
  constructor(translations) {

    // locale specifying which translation to display (default to 'en')
    this.locale = 'en';

    // translation dictionary
    this.translations = translations || {};
  }

  /**
   * Looks up the provided key in the translations dictionary for the current locale and returns the associated
   * translation if avaiable.
   * @param key key of the desired string in the translations dictionary
   * @returns localized string
   */
  localize(key) {

    // look for bundle starting with most specific and falling back to default (e.g. es-mx -> es -> en)
    let bundle = null;
    let lowerCaseLocale = this.locale.toLowerCase();
    let localePreference = [ lowerCaseLocale, lowerCaseLocale.split('-')[0], 'en'];

    for(let i = 0; i < localePreference.length; i++) {
      if(this.translations[localePreference[i]]) {
        bundle = this.translations[localePreference[i]];
        break;
      }
    }

    // if bundle or key missing, return an explanation.
    if(!bundle || !bundle[key]) {
      return `[missing translation : ${key} (${this.locale})]`;
    }

    return bundle[key];
  }
}
