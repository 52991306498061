(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@okta/okta-signin-widget"));
	else if(typeof define === 'function' && define.amd)
		define(["@okta/okta-signin-widget"], factory);
	else if(typeof exports === 'object')
		exports["AssurantOktaSignIn"] = factory(require("@okta/okta-signin-widget"));
	else
		root["AssurantOktaSignIn"] = factory(root["OktaSignIn"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__okta_okta_signin_widget__) {
return 