import OktaSignIn from '@okta/okta-signin-widget';
import I18N from './I18N';
import xhook from 'xhook';
import { UriBuilder } from 'uribuilder';
import merge from 'deepmerge';

import messages from './messages.json';

const i18n = new I18N();
const MAX_INTERNAL_USER_LOGIN_LENGTH = 7;
const OKTA_AUTHN_ENDPOINT = /\/api\/v1\/authn$/;
const OKTA_FORGOT_PASSWORD_ENDPOINT = /\/api\/v1\/authn\/recovery\/password/;
const OKTA_VERIFY_FACTOR_ENDPOINT = /\/api\/v1\/authn\/factors\/(.*)\/verify/;
const OKTA_ENROLL_FACTOR_ENDPOINT = /\/api\/v1\/authn\/factors/;
const UNSUPPORTED_PASSWORD_RESET_MSG = 'assurant.unsupportedPasswordReset.internalUsers';
const DEFAULT_DOMAIN_SUFFIX = '@oktauserdomain.com';
const EMAIL_FACTOR_ID_PREFIX = 'emf';
const SMS_FACTOR_ID_PREFIX = 'sms';
const FACTOR_ID_TOKEN = '{factorId}';
const DEFAULT_RECOVERY_TOKEN_PARAM = 'recovery_token';
const NEEDS_REMEMBER_DEVICE_FIX = /\?.*\?rememberDevice/;

/**
 * Exteneded version of OktaSignIn widget with Assurant customizations
 *
 * <ul>
 *   <li>Built-in username transformation to meet Assurant's Okta Username requirements (i.e. app prefix and
 *   "@oktauserdomain.com")</li>
 *   <li>The widget's calls to the Okta API can be intercepted and redirected to an alternate service for custom
 *   handling of the request. For example, for password recovery, an app might to send its own e-mail instead of
 *   the standard Okta e-mail functionality.</li>
 *   <li>Assurant-specific process validations (e.g. cannot reset an internal user's password)</li>
 *   <li>Automatically handle a recovery token found in the URL and continue recovery processing.</li>
 *   <li>Configurable callback function for existing session handling.</li>
 * </ul>
 */
export default class AssurantOktaSignIn extends OktaSignIn {

  /**
   * Creates a new instance of the AssurantOktaSignIn widget
   *
   * @param options widget options
   */
  constructor(options) {
    initMultilanguageSupport(options);
    initOptions(options);
    addHooks(options);
    super(options);
    handleExistingSession(this, options);
  }
}

/**
 * Initializes multi-language support.
 *
 * @param options
 */
function initMultilanguageSupport(options) {

  // note: Okta widget doesn't expose any i18n functionality so we have to
  // maintain 2 sets of translations. One for our widget extension (I18N)
  // and a separate one for the underlying Okta widget (options.i18n)

  // internal translation dictionary
  i18n.translations = messages;

  // merge embedded translations with provided translations and configure
  // on underlying okta widget
  const provided = options.i18n || {};
  options.i18n = merge(messages, provided);

  // if language set on the widget, use it, otherwise stick with default (en)
  if(options.language) {
    i18n.locale = options.language;
  }
}

/**
 * Sets default values if applicable and performs validation of Assurant-specific options.
 * @param options - the options passed into the signin widget's constructor when created
 */
function initOptions(options) {

  // validations
  if(!options.assurant.appCode) {
    throw new Error('options.assurant.appCode must be provided');
  }

  // default values
  options.assurant.enableInternalUserPasswordRecovery = options.enableInternalUserPasswordRecovery || true;

  // returning with recovery token?
  const builder = UriBuilder.parse(location.href);
  const params = builder.query;
  const recoveryTokenParam = options.assurant.recoveryTokenParam || DEFAULT_RECOVERY_TOKEN_PARAM;
  if(params[recoveryTokenParam]) {
    options.recoveryToken = params[recoveryTokenParam];
  }

  // if no transformUsername provided, use Assurant default
  if(!options.transformUsername) {
    options.transformUsername = username => {

      // assume external user if username length is > 7 characters
      if(username.length > MAX_INTERNAL_USER_LOGIN_LENGTH) {

        // prepend app code
        username = options.assurant.appCode.toUpperCase() + '__' + username;

        // append @oktauserdomain.com if username isn't already in an e-mail format
        if(!username.includes('@')) {
          username = username + DEFAULT_DOMAIN_SUFFIX;
        }
      }

      return username;
    };
  }
}

/**
 * Adds any required request/response hooks (e.g. redirect Okta API call to a different endpoint)
 *
 * @param options - the widget options
 */
function addHooks(options) {

  xhook.before((req, callback) => {

    // primary auth
    if (needsPrimaryAuthIntercept(req, options)) {
      setRequestLanguage(req, options);

      // save original request URL for use in "hide on-prem MFA" xhook.after below
      req.originalUrl = req.url;

      return redirectPrimaryAuthRequest(req, options, callback);
    }

    // reset password
    if (needsForgotPasswordIntercept(req, options)) {
      setRequestLanguage(req, options);
      return redirectForgotPasswordRequest(req, options, callback);
    }

    // verify mfa
    if (needsVerifyFactorIntercept(req, options)) {
      setRequestLanguage(req, options);
      return redirectVerifyFactorRequest(req, options, callback);
    }

    // enroll mfa
    if (needsEnrollFactorIntercept(req, options)) {
      setRequestLanguage(req, options);
      return redirectEnrollFactorRequest(req, options, callback);
    }

    fixRememberDeviceQueryParam(req, options);

    return callback();
  });

  // hide on-prem mfa factor
  xhook.after((req, res, callback) => {

    if(req.url.match(OKTA_AUTHN_ENDPOINT) || (req.originalUrl && req.originalUrl.match(OKTA_AUTHN_ENDPOINT))) {
      const resObj = JSON.parse(res.data);
      if(['MFA_REQUIRED', 'MFA_ENROLL'].indexOf(resObj.status) !== -1  && resObj._embedded && resObj._embedded.factors) {
        resObj._embedded.factors = resObj._embedded.factors.filter(f => f.provider !== 'DEL_OATH');
        res.data = JSON.stringify(resObj);
        res.text = JSON.stringify(resObj);
      }
    }

    return callback();
  });
}

/**
 * Checks for existing Okta session and executes user configured callback if present (existingSessionHandler)
 *
 * @param widget the widget object
 * @param options widget options
 */
function handleExistingSession(widget, options) {

  // execute callback if not in recovery mode, callback exists and session is active
  if(!options.recoveryToken && options.assurant.existingSessionHandler) {

    // session is exposed differently between v2.x and v3.x of widget
    // 2.x: widget.session
    // 3.x: widget.authClient.session
    if(widget.session) {
      widget.session.get(session => {
        if (session.status === 'ACTIVE') {
          options.assurant.existingSessionHandler(session);
        }
      });
    }
    else if(widget.authClient) {
      widget.authClient.session.get()
        .then(session => {
          if (session.status === 'ACTIVE') {
            options.assurant.existingSessionHandler(session);
          }
        });
    }
  }
}

/**
 * Sets Accept-Language header on intercepted requests to match the widget's language configuration (if available)
 * (Note: if language option is not set on the widget, requests will fallback to browswer's language)
 *
 * @param req the request
 * @param options the widget options
 */
function setRequestLanguage(req, options) {
  if(options.language) {
    req.headers['Accept-Language'] = options.language;
  }
}

/**
 * Creates an error response
 *
 * @param errorText the error summary message
 * @return xhook response object
 */
function errorResponse(errorText) {
  return {
    status: 400,
    statusText: 'Bad Request',
    headers: { 'Content-Type': 'application/json' },
    text: `{ "errorSummary": "${errorText}" }`
  };
}

/**
 * Returns whether or not the request is for primary authentication.
 *
 * @param req the request
 * @param options the widget options
 * @returns true if an intercept is required, false otherwise
 */
function needsPrimaryAuthIntercept(req, options) {
  return req.url && options.assurant.primaryAuthEndpoint && req.url.match(OKTA_AUTHN_ENDPOINT);
}

/**
 * Returns whether or not the request is for password reset and an intercept is configured
 *
 * @param req the request
 * @param options the widget options
 * @returns true if an intercept is required, false otherwise
 */
function needsForgotPasswordIntercept(req, options) {
  return req.url && options.assurant.forgotPasswordEndpoint && req.url.match(OKTA_FORGOT_PASSWORD_ENDPOINT);
}

/**
 * Returns whether or not the request is for factor verification and an intercept is configured
 *
 * @param req the request
 * @param options the widget options
 * @returns true if an intercept is required, false otherwise
 */
function needsVerifyFactorIntercept(req, options) {
  return req.url && options.assurant.verifyFactorEndpoint && req.url.match(OKTA_VERIFY_FACTOR_ENDPOINT);
}

/**
 * Returns whether or not the request is for MFA factor enrollment an intercept is configured
 *
 * @param req the request
 * @param options the widget options
 * @returns true if an intercept is required, false otherwise
 */
function needsEnrollFactorIntercept(req, options) {
  return req.url && options.assurant.enrollFactorEndpoint && req.url.match(OKTA_ENROLL_FACTOR_ENDPOINT);
}

/**
 * Fixes the rememberDevice query param if needed.
 *
 * This fix is required when the widget appends an "?rememberDevice=true|false" to a URL that already
 * has a query string, resulting in the URL having 2 query string "?" characters:
 * (i.e. /api/mfa/verify?appId=12345?rememberDevice=true)
 *
 * @param req the request
 * @param options the widget options
 */
function fixRememberDeviceQueryParam(req, options) {
  if(req.url && options.assurant.verifyFactorEndpoint && req.url.match(NEEDS_REMEMBER_DEVICE_FIX)) {
    req.url = req.url.replace('?rememberDevice', '&rememberDevice');
  }
}

/**
 * Updates the current primary authentication request URL to the configured custom URL
 *
 * @param req
 * @param options
 * @param callback
 * @returns callback function
 */
function redirectPrimaryAuthRequest(req, options, callback) {
  req.url = options.assurant.primaryAuthEndpoint;
  return callback();
}

/**
 * Updates the current forgot password request URL to the configured custom URL
 *
 * @param req
 * @param options
 * @param callback
 * @returns callback function
 */
function redirectForgotPasswordRequest(req, options, callback) {
  let endpointObj = options.assurant.forgotPasswordEndpoint;
  let reqObj = JSON.parse(req.body);

  // password reset via Okta only supported for external users unless explicitly enabled
  if(!options.assurant.enableInternalUserPasswordRecovery && reqObj.username
    && reqObj.username.length <= MAX_INTERNAL_USER_LOGIN_LENGTH) {
    return callback(errorResponse(i18n.localize(UNSUPPORTED_PASSWORD_RESET_MSG)));
  }

  // if forgotPasswordEndpoint is:
  // object - intercept only for matching factors
  // string - intercept for all forgot password requests
  if(typeof endpointObj == 'object') {
    let factor = reqObj.factorType.toLowerCase();
    if(endpointObj[factor]) {
      req.url = endpointObj[factor];
    }
  }
  else if (typeof endpointObj == 'string') {
    req.url = options.assurant.forgotPasswordEndpoint;
  }

  return callback();
}

/**
 * Updates the current verify factor request URL to the configured custom URL
 *
 * @param req
 * @param options
 * @param callback
 * @returns callback function
 */
function redirectVerifyFactorRequest(req, options, callback) {
  let endpointObj = options.assurant.verifyFactorEndpoint;
  let factorId = req.url.match(OKTA_VERIFY_FACTOR_ENDPOINT)[1]; // factorId is 1st captured match in regex
  let factorType = '';

  // determine factor type
  if(factorId.startsWith(EMAIL_FACTOR_ID_PREFIX)) {
    factorType = 'email';
  }
  else if(factorId.startsWith(SMS_FACTOR_ID_PREFIX)) {
    factorType = 'sms';
  }
  else {
    // if not email or sms, just pass through to Okta
    return callback();
  }

  // if verifyFactorEndpoint is:
  // object - intercept only for matching factors
  // string - intercept for all verify factor requests
  if(typeof endpointObj == 'object') {
    if(endpointObj[factorType]) {
      req.url = endpointObj[factorType].replace(FACTOR_ID_TOKEN, factorId);
    }
  }
  else if (typeof endpointObj == 'string') {
    req.url = options.assurant.verifyFactorEndpoint.replace(FACTOR_ID_TOKEN, factorId);
  }

  // if "remember device" is selected, add to query string
  if(document.querySelector('input[name="rememberDevice"]:checked')) {
    req.url = UriBuilder.updateQuery(req.url, { rememberDevice: true });
  }

  return callback();
}

/**
 * Updates the current enroll factor request URL to the configured custom URL
 *
 * @param req
 * @param options
 * @param callback
 * @returns callback function
 */
function redirectEnrollFactorRequest(req, options, callback) {
  req.url = options.assurant.enrollFactorEndpoint;
  return callback();
}
